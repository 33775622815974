<template>
 <div class="side-nav" :class="{'side-nav__mobile-hide':mobileSideNav}">
	<div class="side-nav__container">
		<settings-section-card />
	</div>	
</div><!-- side-nav -->
</template>

<script>
import SettingsSectionCard from '@/components/settings/SideCardOptions.vue'
export default {
  name: 'Dashboard',
  components:{ 
    SettingsSectionCard,
  },
  computed:{
    mobileSideNav(){
      return this.$store.state.mobileSideNav
    }
  }
}
</script>

