<template>
    <div class="side-card">
		<h1 class="side-card--heading">Settings</h1>
		<div class="side-card--link-container">
			<router-link class="side-card__link" :to="{name:'user-roles'}">
				<img class="side-card__link-image" :src="require(`@/assets/images/user-roles.svg`)">
				<h1 class="side-card__link-name">User Roles</h1>
			</router-link>
		</div>
		<!-- <div class="side-card--link-container" v-for="link in links" :key="link">
			<router-link class="side-card__link" :to="{path:`/settings/${link}`}">
				<img class="side-card__link-image" :src="require(`@/assets/images/Sections/${link}.svg`)">
				<h1 class="side-card__link-name">{{ link }}</h1>
			</router-link>
		</div> -->
	</div>
</template>

<script>
export default {
	data(){
		return{
			links: ['account','security','billing','metrices']
		}
	},
	computed:{

	}
}
</script>
