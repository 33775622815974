<template>

	<div class="body">
		<div class="container">
			<settings-side-nav  />
			<div class="content">
				<list-header />
				<div class="settings-cards">
					<router-view />
				</div>
			</div>
		</div>
	</div>


  <!-- container -->
</template>

<script>
import ListHeader from '@/components/ListHeader.vue'
import SettingsSideNav from '@/components/settings/SideNav.vue'
export default {
  name: 'Settings',
  components: {
    SettingsSideNav,ListHeader
  },
}
</script>

<style>
	.settings{
		display: grid;
		grid-template-columns: 1fr;
	}	

	.settings-cards{
		padding: 4px;
		overflow-y:scroll;
	}

	.settings-card{
		background-color: #fff;
		padding:2em;
		box-shadow: var(--shadow-card);
	}

	.settings__input,.settings__description{
		padding:12px;
		margin:1em;
		display: block;
		box-shadow: var(--shadow-input);
		border-radius: var(--border-radius);
		
	}
	.settings__description{
		resize: none;
	}
	.settings__heading{
		display: block;
		padding: 0 1rem;
		font-size: 1em;
		font-weight: bold;
	}
	.settings__select{
		border:none;
		padding:8px;
		margin:1em 0;
		margin-left: .25em;
		margin-right: 1em;
		box-shadow: var(--shadow-input);
		border-radius: var(--border-radius);
	} 
  .settings__button-container{
    display: flex;
    padding:1em;
  }
  .settings__button{
    display: block;
    color:#fff;
    font-weight: bold;
    background: var(--bg-dark);
    border:none;
    /* margin:0 4px; */
    padding:12px 24px;
    margin-bottom: 0;
    margin-left:auto;
    box-shadow: var(--shadow-button);
  }
</style>
